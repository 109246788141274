import React from 'react';
import logo from './logo.svg';
import './App.css';
import * as faker from 'faker';
import ImageAsync from 'react-image-async';
import { HipsterBio } from './HipsterBio';
import { AccountButton } from './AccountButton';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonItem, IonLabel } from '@ionic/react';
import { IonButtons, IonButton, IonIcon } from '@ionic/react';
import { search, ellipsisHorizontal, ellipsisVertical } from 'ionicons/icons';



const VirtualAlexa = require("virtual-alexa").VirtualAlexa;

function App() {
  // let alexa;

  // alexa = VirtualAlexa.Builder()
  //   .handler("index.handler")
  //   .interactionModel(
  //     {
  //       "interactionModel": {
  //         "languageModel": {
  //           "invocationName": "hello world",
  //           "types": [],
  //           "intents": [
  //             {
  //               "name": "AMAZON.CancelIntent",
  //               "samples": []
  //             },
  //             {
  //               "name": "AMAZON.HelpIntent",
  //               "samples": []
  //             },
  //             {
  //               "name": "AMAZON.StopIntent",
  //               "samples": []
  //             },
  //             {
  //               "name": "HelloWorldIntent",
  //               "samples": [
  //                 "hello",
  //                 "say hello",
  //                 "hello world"
  //               ],
  //               "slots": []
  //             }
  //           ]
  //         }
  //       }
  //     }
  //   )
  //   .create();

  // alexa.utter("help").then((result) => {
  //   console.log(result);
  // });

  let name = faker.name.firstName(0) + ' ' + faker.name.lastName(0);
  let avatar = faker.image.avatar();
  let phrase = faker.hacker.phrase();

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="secondary">
            <AccountButton />
            <IonButton>
              <IonIcon slot="icon-only" icon={search} />
            </IonButton>
          </IonButtons>
          <IonButtons slot="primary">
            <IonButton color="secondary">
              <IonIcon slot="icon-only" ios={ellipsisHorizontal} md={ellipsisVertical} />
            </IonButton>
          </IonButtons>
          <IonTitle>Hipsum</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent class="ion-padding-start ion-padding-end">
        {/*-- Default Item --*/}
        <IonItem>
          <IonLabel>
            {name}
          </IonLabel>
        </IonItem>
        <ImageAsync src='https://i.pravatar.cc/300'>
          {({ loaded, error }) =>
            loaded ? <img src='https://i.pravatar.cc/300' alt='' /> : <div>Loading...</div>
          }
        </ImageAsync>
        <IonItem>
          <IonLabel>
            Favorite Quote
          </IonLabel>
        </IonItem>
        {phrase}
        <HipsterBio />
      </IonContent>
    </IonPage>
  );
}

export default App;
