import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

import { IonApp, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Route } from 'react-router';

document.body.classList.toggle('dark', true);

ReactDOM.render(
    <IonApp>
        <IonReactRouter>
            <IonRouterOutlet>
                <Route path="/" exact component={App} />
                {/* <Route path="/about" exact component={About} /> */}
            </IonRouterOutlet>
        </IonReactRouter>
    </IonApp>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

const Alexa = require("alexa-sdk");

const handler = function (event, context) {
    console.log(JSON.stringify(event, null, 2));
    const alexa = Alexa.handler(event, context);
    // To enable string internationalization (i18n) features, set a resources object.
    alexa.registerHandlers(handlers);
    alexa.execute();
};

const handlers = {
    "LaunchRequest": function () {
        this.emit("SayHello");
    },
    "HelloWorldIntent": function () {
        this.emit("SayHello")
    },
    "SayHello": function () {
        this.response.speak("Hello World!");
        this.emit(":responseReady");
    },
    "AMAZON.HelpIntent": function () {
        const speechOutput = "This is the Hello World Sample Skill. ";
        const reprompt = "Say hello, to hear me speak.";

        this.response.speak(speechOutput).listen(reprompt);
        this.emit(":responseReady");
    },
    "AMAZON.CancelIntent": function () {
        this.response.speak("Goodbye!");
        this.emit(":responseReady");
    },
    "AMAZON.StopIntent": function () {
        this.response.speak("See you later!");
        this.emit(":responseReady");
    }
};
