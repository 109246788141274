import { IonItem, IonLabel } from '@ionic/react';
import React from 'react';

class HipsterBio extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            totalReactPackages: null,
            bio: '',
        };
    }

    async componentDidMount() {
        // GET request using fetch with async/await
        var url = 'https://hipsum.co/api/?type=hipster-centric&sentences=' + Math.floor((Math.random() * 7) + 1).toString();
        const response = await fetch(url);
        const data = await response.json();
        this.setState({
            totalReactPackages: 0,
            bio: data[0],
        })
    }

    render() {
        const { totalReactPackages, bio } = this.state;
        return (
            <div>
                <IonItem>
                    <IonLabel>
                        Bio
                    </IonLabel>
                </IonItem>
                {bio}
            </div>
        );
    }
}

export { HipsterBio };