/* Using with IonPopover Component */

import React, { useState } from 'react';
import { IonPopover, IonButton, IonList, IonItem, IonIcon } from '@ionic/react';
import { personCircle } from 'ionicons/icons';

const AccountButton: React.FC = () => {
    const [popoverState, setShowPopover] = useState({ showPopover: false, event: undefined });

    return (
        <>
            <IonPopover
                cssClass='my-custom-class'
                event={popoverState.event}
                isOpen={popoverState.showPopover}
                onDidDismiss={() => setShowPopover({ showPopover: false, event: undefined })}
            >
                <IonList>
                    <IonItem>
                        Account
                    </IonItem>
                    <IonItem>
                        Log Out
                    </IonItem>
                </IonList>
            </IonPopover>
            <IonButton onClick={
                (e: any) => {
                    e.persist();
                    setShowPopover({ showPopover: true, event: e })
                }}
            >
                <IonIcon slot="icon-only" icon={personCircle} />
            </IonButton>
        </>
    );
};

export { AccountButton };